import React, { useEffect, useState } from 'react';
import NavBar from './components/Nav';
import Header from './components/Header';
import StepIndicator from './components/StepIndicator';
import axios from 'axios';
import { getProducts, SubscriptionStatus } from './components/SubscriptionComps';
import { starterSubscription, baseSubscription, advancedSubscription } from './components/planData';
import LoaderofTranscript from './components/Loader';

const baseUrl = process.env.REACT_APP_API_URL;
const fetchUser = async () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.jwt}`
    }
  };
  try {
    var resp = await axios.get(process.env.REACT_APP_API_URL + 'api/users/me', config);
    const distro = await axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + 'api/get_latest_version?platform=windows',

    });
    localStorage.setItem('link_to_distro', distro.data.link_to_distro);
    localStorage.setItem('userInfo', JSON.stringify({ ...userInfo, user: resp.data }));


  } catch (error) {
    console.log('not authorised');
  }
};
function PlansComponent({ subscription, buttonAction }) {
  const [expandedPlan, setExpandedPlan] = useState(null);
  const plans = [starterSubscription, baseSubscription, advancedSubscription];

  return plans.map((plan, i) => (
    <div className={`w-full lg:w-1/3 justify-items-end md:py-4 ${i === 1 ? "shadow-xl z-30 md:-mt-5" : ""}`} key={i}>
      <div className="flex h-screen rounded-lg bg-white flex-col justify-between h-full pb-10">
        <h3 className="text-xl font-mono flex mt-8 justify-center">{plan.title}</h3>
        <p className={`pt-4 font-sans text-sm px-10 text-center ${expandedPlan === i ? '' : 'hidden md:block'}`}>{plan.description}</p>
        <ul className={`w-full font-sans mt-4 pr-10 pl-10 mb-3 ${expandedPlan === i ? '' : 'hidden md:block'}`}>
          {plan.features.map((feature, index) => (
            <li className="pb-3" key={index} dangerouslySetInnerHTML={{ __html: feature }}></li>
          ))}
        </ul>
        <div className="w-full font-sans mt-4 pr-10 pl-10 mb-3">
          <p className="text-sm">
            <span className="inline text-3xl font-bold mr-3 float-left">{plan.price.display}</span> per user
            <br />
            {plan.price.frequency}
          </p>
          {/* Display the first feature for mobile and allow expansion */}
          {expandedPlan !== i && (
            <>
              <p className="md:hidden mt-4">{plan.description}</p>
              <p className="md:hidden mt-4" dangerouslySetInnerHTML={{ __html: plan.features[0] }} ></p>
            </>
          )}
        </div>
        <div className='w-full md:hidden flex'>

    
    {!(subscription && subscription.subscription_status === 'active') && 
        <div className='w-1/2   flex'>
            <button
                data-url={process.env.REACT_APP_API_URL}
                className="w-full md:hidden SS_ProductCheckout mt-10 mx-4 md:mx-20 h-8 uppercase tracking-wider border-2 border-black bg-teal-400 text-black hover:shadow-lg hover:shadow-teal-500/50"
                data-id={plan.buttonId}
                id={plan.buttonId}
                onClick={buttonAction}
            >
                Subscribe
            </button>
        </div> 
    }
        {expandedPlan !== i && (
         <div className=' w-1/2 flex'>
            <button className="w-full md:hidden mt-10 mx-4 md:mx-20 h-8 uppercase tracking-wider border-2 border-black bg-white text-black hover:shadow-lg hover:shadow-gray-500/50" onClick={() => setExpandedPlan(i)}>Plan details</button>
        </div>
    )}

</div>

        {!(subscription && subscription.subscription_status === 'active') &&
          <button
            data-url={process.env.REACT_APP_API_URL}
            className="hidden md:block SS_ProductCheckout mt-10 mx-4 md:mx-20 h-8 uppercase tracking-wider border-2 border-black bg-teal-400 text-black hover:shadow-lg hover:shadow-teal-500/50"
            data-id={plan.buttonId}
            id={plan.buttonId}
            onClick={buttonAction}
          >
            Buy now
          </button>
        }
      </div>
    </div>
  ));
}


const ProductCheckoutComponent = () => {
  const [subscription, setSubscription] = useState({});
  const [loading, setLoading] = useState(true);




  const SS_ProductCheckout = (productId, userEmail, userId) => {
    setLoading(true);
    localStorage.setItem('strapiStripeUrl', baseUrl);
    const getProductApi = baseUrl + 'strapi-stripe/getProduct/' + productId;
    const checkoutSessionUrl = baseUrl + 'strapi-stripe/createCheckoutSession/';

    fetch(getProductApi, {
      method: 'get',
      mode: 'cors',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then(response => response.json())
      .then(response => {
        console.log({
          stripePriceId: response.stripePriceId,
          stripePlanId: response.stripePlanId,
          isSubscription: response.isSubscription,
          productId: response.id,
          productName: response.title,
          userEmail,
        });

        fetch(checkoutSessionUrl, {
          method: 'post',
          body: JSON.stringify({
            stripePriceId: response.stripePriceId,
            stripePlanId: response.stripePlanId,
            isSubscription: response.isSubscription,
            productId: response.id,
            productName: response.title,
            userEmail,
            userId,
          }),
          mode: 'cors',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        })
          .then(response => response.json())
          .then(response => {
            if (response.id) {
              window.location.replace(response.url);
            }
          });
      });
  }

  const SS_GetProductPaymentDetails = (checkoutSessionId) => {
    const baseUrl = localStorage.getItem('strapiStripeUrl');
    const retrieveCheckoutSessionUrl =
      baseUrl + 'strapi-stripe/retrieveCheckoutSession/' + checkoutSessionId;

    fetch(retrieveCheckoutSessionUrl, {
      method: 'get',
      mode: 'cors',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then(response => response.json())
      .then(response => {
        if (response.payment_status === 'paid') {
          if (
            window.performance
              .getEntriesByType('navigation')
              .map(nav => nav.type)
              .includes('reload')
          ) {
            console.info('website reloaded');
          } else {
            // store payment in strapi
            const stripePaymentUrl = baseUrl + 'strapi-stripe/stripePayment';
            fetch(stripePaymentUrl, {
              method: 'post',
              body: JSON.stringify({
                txnDate: new Date(),
                transactionId: response.id,
                isTxnSuccessful: true,
                txnMessage: response,
                txnAmount: response.amount_total / 100,
                customerName: response.customer_details.name,
                customerEmail: response.customer_details.email,
                stripeProduct: response.metadata.productId,
              }),
              mode: 'cors',
              headers: new Headers({
                'Content-Type': 'application/json',
              }),
            });
          }
        }
      });
  }


  const buttonAction = async (event) => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));


    SS_ProductCheckout(
      event.target.dataset.id,

      userInfo.user.email,
      userInfo.user.customer_id,
    );
  }

  useEffect(() => {
    setLoading(true);

    let userInfo = JSON.parse(localStorage.getItem('userInfo'));

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.jwt}`
      }
    }
    async function loadSubscription(products) {
      var resp = await axios.get(process.env.REACT_APP_API_URL + 'api/getSubscription', config);
      products.forEach(product => {
        if (product.id !== resp.data.subscription_id) {
          resp.data.upgrade_sub = product.id;
        }
      });
      setSubscription({...resp.data, subscription_status:userInfo.user.subscription_status});
      return resp;
    }
    fetchUser().then(getProducts).then(loadSubscription).then(() => setLoading(false))
    userInfo = JSON.parse(localStorage.getItem('userInfo'));


    const ssProduct = document.querySelectorAll('.SS_ProductCheckout');
    if (ssProduct) {
      ssProduct.forEach(product => {
        product.addEventListener('click', async function handleClick(event) {
          const user = await axios.get(baseUrl + 'api/users/me', {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userInfo.jwt}`,
            },
          });
          SS_ProductCheckout(
            event.target.dataset.id,

            userInfo.user.email,
            user.data.customer_id
          );
        });
      });
    }

    const params = new URLSearchParams(document.location.search);
    const checkoutSessionId = params.get('sessionId');
    if (checkoutSessionId) {

      SS_GetProductPaymentDetails(checkoutSessionId);
    }
  }, []);

  return (
    <div className=' flex flex-col h-screen place-items-center'>

      <NavBar />
      <div className="p-2 w-full max-w-5xl">
        <Header name="Subscription" />
        <div className="flex flex-col md:flex-row flex-grow">

          <div
            id="main"
            className="flex flex-row flex-wrap md:py-4 w-full"
          >



            <div className="w-full   px-2">

              <div id="plans" className="  bg-white  flex flex-col  md:flex-row    w-full justify-center  ">
                <div className=" max-w-screen-xl  p-4  flex flex-row flex-wrap h-full w-full   ">
                  {!loading && subscription && (subscription.subscription_status === 'active'|| subscription.subscription_status==='past_due') && <SubscriptionStatus subscription={subscription} />}
                  {!loading && (!subscription || (subscription.subscription_status !== 'active'&& subscription.subscription_status !== 'past_due')) && <><StepIndicator currentStep={1} /></>}
                  {!loading && <div className='flex flex-col  md:flex-row'><PlansComponent subscription={subscription} buttonAction={buttonAction} /></div>}
                  {loading && <div className='flex items-center w-full h-full justify-center px-5 py-3'><LoaderofTranscript /></div>}


                </div>
              </div>


            </div>


          </div>
        </div>
      </div>

    </div>

  )
}

export default ProductCheckoutComponent;
