import axios from "axios";
 const baseUrl = process.env.REACT_APP_API_URL;

export function PromoCodeBlock() {
  let promoCode = localStorage.getItem('promoCode');

  localStorage.removeItem('promoCode');
  if (promoCode === null) {
    return <></>
  } else {
    return (
      <div className="flex flex-col w-full items-center justify-center bg-yellow-500 text-white text-lg p-5  shadow-lg">
        <p>Use <span className="font-bold">{promoCode}</span> promo code on checkout for a free week on a starter plan!</p>
      </div>
    );
  }
}

export async function getProducts() {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.jwt}`,
    },
  };
  const resp = await axios.get(baseUrl + 'api/getProducts', config);
  // setProducts(resp.data);
  return resp.data;
}

export  function SubscriptionStatus   ({ subscription })   {
  return (
    <div>
      <div className="w-full p-2 md:p-10 h-fit flex flex-col md:flex-row pt-0 md:pt-0">
        Your subscription is: {subscription.subscription_name}
      </div>
      {subscription.subscription_status === 'past_due'&&<div className="w-full p-2 md:p-10 h-fit flex flex-col md:flex-row pt-0 md:pt-0">
        You missed a payment. <a className="cursor-pointer underline color-teal" href={subscription.link}>Please update your payment details. </a>
      </div>}
      <div className="w-full p-2 md:p-10 h-fit flex flex-col md:flex-row pt-0 md:pt-0">
        Used hours for live transcription this month: {subscription.used_liveseconds / (60 * 60)} / {subscription.liveseconds / (60 * 60)}
        <br />
        Used hours for file transcription this month: {subscription.used_fileseconds / (60 * 60)} / {subscription.fileseconds / (60 * 60)}
      </div>
      <div className="w-full p-2 md:p-10 h-fit flex flex-col md:flex-row pt-0 md:pt-0 justify-start">
        <a className="cursor-pointer" href={subscription.link}>
          <button className=" mt-10 px-2 md:mr-20 h-8 uppercase tracking-wider border-2 border-black bg-teal-400 text-black hover:shadow-lg hover:shadow-teal-500/50">
            Upgrade or cancel subscription
          </button>
        </a>
      </div>
    </div>
  );
};

export function  Plan  ({ title, description, price, features, activeSubscription, onButtonClick, buttonId })  { return(
  <div className="flex h-screen rounded-lg bg-white flex-col justify-between h-full pb-10 ">
      <h3 className='text-xl font-mono flex mt-8 justify-center'>{title}</h3>
      <p className='pt-4 font-sans text-sm px-10 text-center'>
          {description}
      </p>
      <ul className={' w-full font-sans mt-4 pr-10 pl-10 mb-3'}>
          {features.map((feature, index) => (
              <li key={index} className='pb-3'>{feature.icon} &nbsp; {feature.text}</li>
          ))}
      </ul>
      <div className={' w-full font-sans mt-4 pr-10 pl-10 mb-3'}>
          <p className="text-sm"> 
              <span className="inline text-3xl font-bold mr-3 float-left">${price.amount}</span> per user<br /> {price.frequency}
          </p>
      </div>
      {!activeSubscription && <button
          data-url={process.env.REACT_APP_API_URL}
          className="SS_ProductCheckout mt-10 mx-4 md:mx-20 h-8 uppercase tracking-wider border-2 border-black bg-teal-400 text-black hover:shadow-lg hover:shadow-teal-500/50"
          data-id={buttonId}
          id={buttonId}
          onClick={onButtonClick}
      >Buy now</button>}
  </div>
)};
