import React from 'react';

const StepIndicator = ({ currentStep }) => {
    const stepsDescription = ['Sign Up', 'Pick Subscription', 'Download app'];
  
    return (
      <div className="flex w-full items-center justify-between">
        {stepsDescription.map((description, step) => (<>
          <div key={step} className="flex flex-col items-center">
            {/* Circle */}
            
            <div
              className={`w-8 h-8 rounded-full flex items-center justify-center mb-2 ${
                currentStep > step ? 'bg-teal-500' : 'border-2 border-teal-500'
              }`}
            >
              {currentStep > step ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              ) : (
                <span className="text-teal-500">{step + 1}</span>
              )}
            </div>
  
            {/* Text Description */}
            <div
              className={`text-sm ${
                currentStep > step ? 'text-teal-500' : 'text-gray-500'
              }`}
            >
              {description}
            </div>
  
            {/* Line connecting circles */}
         
          </div>   {step !== stepsDescription.length - 1 && (
            <div
              className={`flex-grow border-t-2 mx-2 mb-5 ${
                currentStep > step ? 'border-teal-500' : 'border-gray-300'
              }`}
            ></div>
            )} </>
        ))}
      </div>
    );
  };
  
 

export default StepIndicator;
