/* eslint-disable */

import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import LoaderofTranscript from './components/Loader';
import faces from '../../src/images/faces.png'
import tf from '../images/talkflow_black.png'
const LoginPage = ({ history }) => {

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const signIn = useGoogleLogin({
        onSuccess: codeResponse => { responseGoogle(codeResponse); },
        // ... you can also handle other properties like onFailure here
    });
    const login = () => {
        setLoading(true);
        signIn();
    }
    const responseGoogle = async (response) => {
        if (!response?.access_token) {
            console.error("Google Login Error", response);
            return;
        }

        try {
            const strapiRes = await axios({
                method: 'get',
                url: process.env.REACT_APP_API_URL + 'api/auth/google/callback?access_token=' + response.access_token,

            });
            const distro = await axios({
                method: 'get',
                url: process.env.REACT_APP_API_URL + 'api/get_latest_version?platform=windows',

            });


            localStorage.setItem('userInfo', JSON.stringify(strapiRes.data));
            localStorage.setItem('link_to_distro', distro.data.link_to_distro);
            strapiRes.data.user.subscription_status && strapiRes.data.user.subscription_status === 'paid' ? navigate("/fromfiles") : navigate("/subscription");
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (!userInfo || !userInfo.jwt) {
            return
        }

        const fetchUser = async () => {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userInfo.jwt}`
                }
            };
            try {
                var resp = await axios.get(process.env.REACT_APP_API_URL + 'api/users/me', config);
                const distro = await axios({
                    method: 'get',
                    url: process.env.REACT_APP_API_URL + 'api/get_latest_version?platform=windows',

                });
                localStorage.setItem('link_to_distro', distro.data.link_to_distro);
                localStorage.setItem('userInfo', JSON.stringify(resp.data));

                resp.data.user.subscription_status && resp.data.user.subscription_status === 'paid' ? navigate("/fromfiles") : navigate("/subscription");

            } catch (error) {
                console.log('not authorised');
            }
        };

        setLoading(true);
        fetchUser().then(() => {
            setLoading(false);
        }
        );
    }, []);
    return (
        <div className='flex items-center justify-between px-5 py-3 md:flex-row flex-col h-full'>
            <div className="bg-crosses flex w-full md:w-1/2 justify-center items-center h-full" id="google-btn">
                {loading ?
                    <div className='flex items-center h-full justify-center px-5 py-3'><LoaderofTranscript /></div>
                    :
                    <div className='flex items-center flex-col mt-10 md:mt-0 '  >
                        <img className="w-1/5 mb-10" src={tf} />

                        <button onClick={login}
                            className="flex   px-5 py-3 bg-white   h-16 w-64 text-md uppercase leading-7	  align-middle content-center items-center justify-center tracking-wider border-2 border-slate-950  text-slate-950 shadow-sm hover:shadow-lg shadow-slate-950/50">
                            <img className="h-4 mr-4" src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg"
                                alt="Google sign-in" />
                            <span>Log in</span>
                        </button>

                        <div className='my-5'>OR</div>
                        <button onClick={login}
                            className="flex   px-5 py-3 bg-white   h-16 w-64 text-md uppercase leading-7	  align-middle content-center items-center justify-center tracking-wider border-2 border-slate-950  text-slate-950 shadow-sm hover:shadow-lg shadow-slate-950/50">
                            <img className="h-4 mr-4" src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg"
                                alt="Google sign-in" />
                            <span> Sign up </span>
                        </button>
                    </div>
                }
            </div>
            <div className='flex w-full md:w-1/2 justify-center items-center   p-10 flex-col'>
                <h2 className="font-sans text-xl  mt-10 text-center">Hundreds of developers got the job with TalkFlow </h2>
                <h2 className="font-sans text-xl mb-10  text-center">Join to access intelligent proven tool that helps to impress!</h2>

                <img className="w-4/5" src={faces} />
            </div>
        </div>

    );
}

export default LoginPage;
