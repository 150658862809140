import React, {  useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import tf from '../../images/talkflow.png'

const Navbar = () => {
    const logOut = () => {
        localStorage.removeItem('userInfo');
        navigate("/");
    }
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const [userData] = useState(userInfo.user);
    const navigate = useNavigate();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <nav aria-label="menu nav" className="bg-black pt-4 md:pt-4 pb-4 px-1 mt-0   w-full z-20 top-0">
            <div className="container mx-auto px-4 md:px-0">
                <div className="flex justify-between items-center">
                    <a href="/"><img alt="TalkFlow" className='h-6 md:h8' src={tf} /></a>
                    <div className='flex'>
                        <div className={"md:hidden flex "+(isMenuOpen?" mr-4":"")}>
                            <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
                                <i className={!isMenuOpen?"text-white fas fa-bars":"text-white fas fa-xmark"}></i>
                            </button>
                        </div>

                        <div className={`md:flex ${isMenuOpen ? 'flex' : 'hidden'}`}>
                            <ul className="list-reset flex flex-col md:flex-row">
                                <li className="flex flex-1 md:flex-none md:mr-4 mb-2 md:mb-0 items-center">
                                    <a className="text-white hover:text-gray-200" href='/pages/download'>Download</a>
                                </li>
                                <li className="flex flex-1 md:flex-none md:mr-4 mb-2 md:mb-0 items-center">
                                    <NavLink className="text-white hover:text-gray-200" to="/live">Live Transcripts</NavLink>
                                </li>
                                <li className="flex flex-1 md:flex-none md:mr-4 mb-2 md:mb-0 items-center">
                                    <NavLink className="text-white hover:text-gray-200" to="/fromfiles">From files</NavLink>
                                </li>
                                <li className="flex flex-1 md:flex-none items-center ">
                                    <div className="relative inline-block text-left" id="left-menu-button">
                                        <div>
                                            <div
                                                type="button"
                                                className="inline-flex justify-center w-full  md:px-4 py-2  text-xs md:text-sm font-medium text-white underline underline-offset-8"
                                                id="user-menu"
                                                aria-haspopup="true"
                                                aria-expanded="true"
                                            >
                                                👤&nbsp;{userData.username}
                                                {/* Heroicon name: chevron-down */}
                                            </div>
                                        </div>
                                        <div
                                            id="left-menu"
                                            className="origin-top-right absolute right-0 mt-0 w-48 rounded-md shadow-lg bg-white px-4 ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                            aria-orientation="vertical"
                                            aria-labelledby="options-menu"
                                        >
                                            <div className="py-1" role="none">
                                                <button
                                                    onClick={() => navigate("/subscription")}
                                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                    role="menuitem"
                                                >📝&nbsp;Subscription</button>
                                            </div>
                                            <div className="py-1" role="none">
                                                <button
                                                    onClick={() => logOut()}
                                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                    role="menuitem"
                                                >
                                                    🚪 Log out
                                                </button>
                                            </div>

                                        </div>
                                    </div>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;
