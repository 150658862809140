/* eslint-disable */

import { React, useEffect, useState } from 'react';
import axios from 'axios';
import tf from '../images/talkflow.png'
import Navbar from '../pages/components/Nav';
import { useParams } from 'react-router-dom';


const Page = () => {
    const { pagename } = useParams();

    const [content, setContent] = useState('');
    //
    const [links, setLinks] = useState([]);

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + 'api/get_pages?menu=footer').
            then(function (response) {
                setLinks(response.data);
            }
            )


        axios.get(process.env.REACT_APP_API_URL + 'api/get_content?slur=' + pagename)
            .then(function (response) {
                setContent(response.data.Content);
            }
            )

    }, [])
    return (
        <div className="font-mono flex flex-col h-screen overflow-x-hidden">
            {!userInfo?<nav
                aria-label="menu nav"
                className="flex bg-slate-950  md:pt-1   px-4   content-center flex-grow w-full z-20 top-0    md:max-h-14 justify-start md:justify-center py-4 "
            >
                <div className='flex content-center w-full max-w-screen-xl md:px-10 justify-space'>
                    <div className="flex flex-wrap items-center w-full md:px-10">

                        <div className="flex w-1/2 pt-2 items-center   text-xs md:text-base">

                            <ul className="list-reset flex justify-between flex-1 md:flex-none items-center">

                                <li className=" md:flex-none mr-10">
                                    <div className="relative inline-block md:text-right cursor-pointer"  >
                                        <div>
                                            <a href="/"
                                                className="inline-flex justify-center w-full  cursor-pointer     text-xs md:text-sm font-medium text-white  "
                                            >
                                                <img style={{ maxHeight: "25px" }} src={tf} />
                                            </a>
                                        </div>

                                    </div>

                                </li>

                                <li className="hidden md:flex md:flex-none mr-5">
                                    <div className="relative inline-block md:text-right"  >
                                        <div>
                                            <div
                                                className="inline-flex justify-center w-full cursor-pointer   py-2  text-xs md:text-sm font-medium text-white  "

                                            >
                                                <a className='' href="/">Main</a>

                                            </div>
                                        </div>

                                    </div>

                                </li>
                                <li className="hidden md:flex md:flex-none mr-5">
                                    <div className="relative inline-block md:text-right"  >
                                        <div>
                                            <div
                                                className="inline-flex justify-center w-full cursor-pointer   py-2  text-xs md:text-sm font-medium text-white  "

                                            >
                                                <a className='' href="/pages/features">Features</a>

                                            </div>
                                        </div>

                                    </div>

                                </li>

                                <li className=" hidden md:flex md:flex-none mr-5">
                                    <div className="relative inline-block md:text-right"  >
                                        <div>
                                            <div
                                                className="inline-flex justify-center w-full  cursor-pointer  py-2  text-xs md:text-sm font-medium text-white  "

                                            >
                                                <a className='' href="/#plans">Plans</a>
                                            </div>
                                        </div>

                                    </div>

                                </li>


                            </ul>

                        </div>
                        <div className="flex w-1/2 pt-2 md:content-center   text-xs md:text-base md:justify-end">
                            <ul className="list-reset flex justify-end items-end flex-1 md:flex-none md:items-center">



                                {/* <li className=" md:flex-none ml-5 mr-5 ">
                                    <div className="relative inline-block text-left"  >
                                        <div>
                                            <div
                                                className="inline-flex justify-center w-full py-2 cursor-pointer text-xs md:text-sm font-medium text-white  "

                                            >
                                                <a className='' href="/#plans">Plans</a>
                                            </div>
                                        </div>

                                    </div>

                                </li> */}
                                <li className=" md:flex-none md:ml-5">
                                    <div className="relative inline-block text-left" id="left-menu-button">
                                        <div>
                                            <div
                                                className="inline-flex justify-center w-full py-2  cursor-pointer text-xs md:text-sm font-medium text-white underline underline-offset-8"

                                            >
                                                <a className='' href="/login">Login</a>

                                            </div>
                                        </div>

                                    </div>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>:<Navbar/>}

            <div className="flex flex-column   justify-center flex-grow " >
                <div className=" font-sans w-full flex z-30 p-4 px-20 md:px-30 py-10  max-w-screen-xl  flex-col ">
                    <div dangerouslySetInnerHTML={{ __html: content }}></div>


                </div>
            </div>

            <footer className="bg-slate-950 text-white text-center p-4 mt-8">
                {/* Footer content goes here */}
                <div style={{ "margin": "auto" }} className='flex max-w-screen-xl py-5 flex-col  md:flex-row p-4 px-20 md:px-30' >
                    <div className="   z-30  flex flex-col flex-wrap h-full w-full align-end ">
                        {links.map((link) => <div className='z-40 cursor-pointer flex'><a className='cursor-pointer' href={"/pages/" + link.slur}>{link.title}</a></div>)}
                    </div>
                    <div className='flex flex-col mt-3 md:mt-0'>
                        <div>
                            <img className='flex' style={{ maxHeight: "25px", opacity: 0.9 }} src={tf} />

                        </div>
                        <div>
                            <a className="flex text-left" href="mailto:contact@talkflow.app">contact@talkflow.app</a>


                        </div>

                    </div>
                </div>

            </footer>
        </div>
    );
}

export default Page;
