/* eslint-disable */
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Conversation from './components/Conversation'
import NavBar from './components/Nav'
import TranscriptList from './components/TranscriptList';

import Header from './components/Header';

import LoaderofTranscript from './components/Loader';
const LivePage = () => {

    const [transcripts, setTranscripts] = useState([]);
    const [transcript, setTranscript] = useState([]);
    const [transcriptLoading, setTranscriptLoading] = useState(false);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.jwt}`
        }
    }

    const getCurrentTranscript = async (id) => {
        setTranscriptLoading(true);
        ;

        var resp = await axios.get(process.env.REACT_APP_API_URL+'api/get_session_transcripts?sessionID=' + id, config);
        setTranscriptLoading(false);
        setTranscript(resp.data);
    }

    useEffect(async () => {
        setTranscriptLoading(true);

        const resp = await axios.get(process.env.REACT_APP_API_URL+'api/get_sessions', config);

        setTranscripts(resp.data);
        setTranscriptLoading(false);

    }, []);

    return (
        <div className='h-screen flex flex-col h-screen place-items-center'>

            <NavBar />
            <div className="p-2 w-full max-w-5xl">
                <Header name="Your live sessions" />
                <div className="flex flex-col md:flex-row flex-grow">

                    <div
                        id="main"
                        className="flex flex-row flex-wrap md:py-4 w-full"
                    >
                        {transcripts.length > 0 ? <>
                            <div className=" w-full  md:w-1/4 px-2 content-center">
                                <div className="  content-center text-left justify-between">
                                <div style={{maxHeight:'calc(100vh - 240px)'}} className="md:overflow-y-auto scroll-box ">

                                    <TranscriptList transcripts={transcripts} setTranscript={getCurrentTranscript} />
                                    </div>
                                </div>
                            </div>

                            <div className="w-full   md:w-3/4 px-2 md:overflow-y-auto scroll-box" style={{maxHeight:'calc(100vh - 240px)'}}  >
                                
                                {transcriptLoading ? <LoaderofTranscript /> : (transcript.length>0&& <Conversation messages={transcript} />)}
                            </div></> : <div className=" w-full   px-6 content-center"><a className="text-teal-400" href="https://speech2textstorageblob.blob.core.windows.net/public/app_v0.0.15.zip">Download an app</a> to use live assistant and create live transcriptions</div>
                        }

                    </div>
                </div>
            </div>

        </div>

    );
}

export default LivePage;
