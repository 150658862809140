import React  from 'react';

const LoaderofTranscript = (props) => {
    return (
        <div className="mt-8 flex justify-center  ">

            <div className="lds-ripple"><div></div><div></div></div>
        </div>
    )
}

export default LoaderofTranscript