/* eslint-disable */
import React, {  useState } from 'react';
function toHumanReadable(dateString) {
    const date = new Date(dateString);
    const now = new Date();

    // Compare dates without considering time
    const diffDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));

    const hour = date.getHours();
    const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    let timeSuffix = 'am';
    if (hour >= 12) {
        timeSuffix = 'pm';
    }

    let readableHour = hour % 12;
    if (readableHour === 0) {
        readableHour = 12;
    }

    let time = `${readableHour}:${minutes}${timeSuffix}`;

    // Modify comparison to check if date is today
    if (now.getDate() === date.getDate() && now.getMonth() === date.getMonth() && now.getFullYear() === date.getFullYear()) {
        return `Today at ${time}`;
    } else if (diffDays < 1) {
        return `Yesterday at ${time}`;
    } else {
        return `${diffDays} days ago at ${time}`;
    }
}

const TranscriptList = (props) => {
    const [active, setActive] = useState(0);

    let transcripts = props.transcripts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    return (

        <ul className=" overflow-x-auto md:overflow-x-visible list-reset flex flex-row md:flex-col  md:pb-3 px-1 md:px-2   md:text-left md:mb-0 md:pb-0 pb-2 mb-2">
            {transcripts.map((transcript, i) => (

                <li key={i} className={i===active?"relative menushadow text-sm mr-3 flex-1 text-teal-400":"relative menushadow text-sm mr-3 flex-1"}>
                    {i===active&&<><div style={{ position: 'absolute', top: '0px', right: '0px', width: '5px', height: '5px', borderRight: '1px solid black', borderTop: '1px solid black' }}></div>
                    <div style={{ position: 'absolute', bottom: '0px', right: '0px', width: '5px', height: '5px', borderRight: '1px solid black', borderBottom: '1px solid black' }}></div>
                    </>}
                    <a
                        href="#"
                        onClick={() => {setActive(i); props.setTranscript(transcript.UUID)}}
                        className="block py-1 md:py-3 pl-1 align-middle  no-underline hover:text-black-200 "
                    >
                        {transcript.name?<span className="pb-1 md:pb-0 text-xs    block md:inline-block text-sm">
                        📼 {transcript.name}</span>:(transcript.original_file?<span className="pb-1 md:pb-0 text-xs    block md:inline-block text-sm">
                        📼 {transcript.original_file.name}</span>:"")
                        }
                        <div className="pt-2 w-full pr-4 pb-1 md:pb-0 text-xs text-left   block md:inline-block text-sm">
                            {toHumanReadable(transcript.createdAt)}
                        </div>
                    </a>
                </li>))}
        </ul>

    )
}

export default TranscriptList;