import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
 const Conversation = (props) => {
    var messages = [];
    if (props.messages[0]&&props.messages[0].time) 
    {messages = props.messages.sort((a, b) => new Date(a.time) - new Date(b.time)).filter((message) => message.text !== "")}
    else {
        messages = props.messages.filter((message) => message.text !== "")
    }
    
    var typemap = {
        "speaker": "speakerMessage",
        "mic": "userMessage",
        "ai": "aiMessage",

    }

    const downloadTxtFile = () => {
        const element = document.createElement("a");
        let fileContent = '';

        messages.forEach(item => {
            const speaker = item.source === "mic" ? "User" :
                item.source === "speaker" ? "Speaker" :
                    "AI";
            fileContent += `${speaker}:\n${item.text}\n\n`;
        });

        const file = new Blob([fileContent], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "myFile.txt";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    return (
       <> 
            <div className="p-4 chat-box bg-white  relative text-sm   w-full shadow-inner" id="chatBox">
            <div className='flex justify-end items-end mb-2 '> 
            <button
                onClick={downloadTxtFile}
                className=" mb-4 px-6 h-8 uppercase  tracking-wider border-2 border-black bg-teal-400 text-black hover:shadow-lg hover:shadow-teal-500/50"
                id="toggleBtn">Download</button>
                </div>
                {
                     messages.length > 0 && messages.map((message, i) => (
                        <div key={i} className={typemap[message.source]}>
                            <ReactMarkdown
                                components={{
                                    code({ node, inline, className, children, ...props }) {
                                        const match = /language-(\w+)/.exec(className || '')
                                        return !inline && match ? (
                                            <SyntaxHighlighter
                                                {...props}
                                                children={String(children).replace(/\n$/, '')}
                                                style={darcula}
                                                language={match[1]}
                                                PreTag="div"
                                            />
                                            
                                        ) : (
                                            <code {...props} className={className}>
                                                {children}
                                            </code>
                                        )
                                    }
                                }}
                            >{message.text}</ReactMarkdown>
                        </div>
                    ))}




            </div> </>
    )

}

export default Conversation;