/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';

import RenderedTranscript from './components/RenderTranscript'
import NavBar from './components/Nav'
import TranscriptList from './components/TranscriptList';

import Header from './components/Header';

import LoaderofTranscript from './components/Loader';
import { useNavigate } from 'react-router-dom';


const FromFiles = () => {
    let navigate = useNavigate();
    const [transcripts, setTranscripts] = useState([]);
    const [transcript, setTranscript] = useState({});
    const [transcriptLoading, setTranscriptLoading] = useState(false);
    const [init, setInit] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [cancelTokenSource, setCancelTokenSource] = useState(null);

    const fileInputRef = useRef();

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.jwt}`
        }
    }

    const getCurrentTranscript = async (id) => {
        setTranscriptLoading(true);


        var resp = await axios.get(process.env.REACT_APP_API_URL + 'api/fetch_transcription_by_UUID?UUID=' + id, config);
        setTranscriptLoading(false);
        setTranscript(resp.data);


    }
    const onFileInputChange = async (event) => {
        setUploading(true);
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("name", "Web upload");
        formData.append("prompt", "Web upload");

        const cancelToken = axios.CancelToken;
        const source = cancelToken.source();
        setCancelTokenSource(source);

        try {
            await axios.post(
                process.env.REACT_APP_API_URL + 'api/create-transcription',
                formData,
                { headers: { ...config.headers, 'Content-Type': 'multipart/form-data' }, cancelToken: source.token }
            );
            setUploading(false);
            await getTranscripts(); // You might want to change this as per your backend response
            setInit(0);
            // setTimeout(()=>{ getCurrentTranscript(transcripts[0].UUID)}, 1000);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.log(error);
            }
            setUploading(false);
        }
        finally {
            // Clear the value of file input to ensure onChange is triggered even when the same file is selected again
            event.target.value = null;
            setUploading(false);

        }
    };

    const onUploadButtonClick = () => {
        fileInputRef.current.click();


    };

    const onCancelUpload = () => {
        if (cancelTokenSource) {
            cancelTokenSource.cancel('User cancelled the upload');
        }
    };
    const getTranscripts = async () => {
        setTranscriptLoading(true);
        //navigate to / if not logged in
        var resp;
        try {
            resp = await axios.get(process.env.REACT_APP_API_URL + 'api/fetch_transcriptions_by_user', config);
        } catch (err) {
            if (err.response.status === 401) navigate('/');
            else console.log(err);
        }

        setTranscriptLoading(false);

        setTranscripts(resp.data);
        if (resp.data.length > 0) {
            getCurrentTranscript(resp.data[0].UUID);
            setInit(0);
        }
    }
    useEffect(async () => {
        getTranscripts();
    }, []);

    return (
        <div className='h-screen flex flex-col h-screen place-items-center p-0'>

            <NavBar />
            <div className="p-2 pb-0 w-full max-w-5xl">
                <Header name="Transcripts of file uploads" />
                <div className="flex flex-col md:flex-row flex-grow">

                    <div
                        id="main"
                        className="flex flex-row flex-wrap md:py-4 w-full"
                    >
                        <div className=" w-full  md:w-1/4 px-2 content-center">
                            <div className="    content-center text-left justify-between">
                                <div className='pl-4 mb-4 flex' >

                                    {uploading ? <div className="custom-loader flex"></div> : <button
                                        onClick={userInfo.user.subscription_status == 'paid'? onUploadButtonClick : ()=>{navigate('/subscription')}}
                                        style={{ opacity: userInfo.user.subscription_status !== 'paid' ? 0.5 : 1 }}
                                        className="px-6 h-8 uppercase text-sm tracking-wider border-2 border-black bg-teal-400 text-black hover:shadow-lg hover:shadow-teal-500/50"

                                    >{userInfo.user.subscription_status !== 'paid'?'Subscribe':'Upload new'}</button>}
                                    {uploading && <button className="flex pl-2 underline underline-offset-8 text-sm" onClick={onCancelUpload}>Cancel Upload</button>}


                                </div>
                                <div style={{ maxHeight: 'calc(100vh - 280px)' }} className="md:overflow-y-auto  scroll-box">
                                    <TranscriptList transcripts={transcripts} setTranscript={getCurrentTranscript} init={init} />
                                </div>
                            </div>
                        </div>

                        <div className="w-full   md:w-3/4 px-2">
                            <div >
                                {transcriptLoading ? <LoaderofTranscript /> : <RenderedTranscript transcript={transcript} updateTranscript={(speakers => setTranscript({ ...transcript, speakers }))} />}
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <input type="file"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={onFileInputChange}
                accept="audio/*,video/*" />

        </div>

    );
}

export default FromFiles;
