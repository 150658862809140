/* eslint-disable */

import { React, useEffect, useRef, useState } from 'react';
import i1 from '../images/l-1.png'
import i2 from '../images/l-2.png'
import i3 from '../images/l-3.png'
import i4 from '../images/l-4.png'
import i5 from '../images/l-5.png'
import i6 from '../images/l-6.png'
import vision from '../images/vision.png'
import screenshot_ns from '../images/screenshot_no_shadow.png'
import tf from '../images/talkflow.png'
import screenshot from '../images/screenshot.png'
import os from '../images/os.png'
import tick from '../images/tick.png'
import mascot from '../images/mascot_ai.png'
import { min } from 'lodash';
import screenshot_wa from '../images/screenshot_wa.png'
import screenshot_wa2 from '../images/screenshot_wa2.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { useNavigate } from 'react-router-dom';
import video from '../images/demo_.mp4';
import gifka from '../images/interface.gif';
// import bg from '../images/bg.svg'
import "swiper/css/navigation";
import emoji from 'react-easy-emoji'
import axios from 'axios';
import 'swiper/css';

import { useLocation } from 'react-router-dom';

function PromoCodeBlock() {
    const location = useLocation();
    const path = location.pathname;

    const promoPlatforms = ['/Reddit', '/Instagram', '/TikTok', '/ProductHunt', 'LinkedIn'];

    // if the current path is not one of the specified promo platforms, don't render anything
    if (!promoPlatforms.includes(path)) {
        return null;
    }

    // extract the platform name from the path and capitalize the first letter
    const platform = path.slice(1)[0].toUpperCase() + path.slice(2).toLowerCase();
    const promoCode = platform.toUpperCase() + 'WEEK';
    localStorage.setItem('promoCode', promoCode);
    return (
        <div className="flex flex-col items-center justify-center bg-yellow-500 text-white text-lg p-5  shadow-lg">
            <h2 className="font-bold text-2xl mb-2">Special Offer!</h2>
            <p>Use <span className="font-bold">{promoCode}</span> promo code on checkout for a free week on a starter plan!</p>
        </div>
    );
}
const LandingPage = () => {
    const navigate = useNavigate();
    const [links, setLinks] = useState([]);

    useEffect(() => {
        let linksArray = axios.get(process.env.REACT_APP_API_URL + 'api/get_pages?menu=footer').
            then(function (response) {
                setLinks(response.data);
            }
            )
        var toggle = document.getElementById('toggle');
        var personal = document.querySelectorAll('.personal');
        var enterprise = document.querySelectorAll('.enterprise');

        toggle.addEventListener('change', function () {
            if (this.checked) {
                personal.forEach(function (el) {
                    el.style.height = '0';
                    el.style.opacity = '0';
                    // display: block;
                    // line-height:0;
                    // height: 0;
                    // overflow: hidden;
                    el.style.display = 'block';
                    el.style.lineHeight = '0';
                    el.style.overflow = 'hidden';


                });
                setTimeout(function () {
                    enterprise.forEach(function (el) {
                        //delete element style
                        el.style = null
                        el.style.height = '100%';
                        el.style.opacity = '1';

                    });
                }, 500)
            } else {
                setTimeout(function () {
                    personal.forEach(function (el) {
                        el.style = null

                        el.style.height = '100%';
                        el.style.opacity = '1';
                    });
                }, 500)

                enterprise.forEach(function (el) {
                    el.style.height = '0';
                    el.style.opacity = '0';
                    el.style.display = 'block';
                    el.style.lineHeight = '0';
                    el.style.overflow = 'hidden';
                });
            }
        });

    }, [])
    return (
        <div className="font-mono flex flex-col h-screen overflow-x-hidden">
            <nav
                aria-label="menu nav"
                className="flex bg-slate-950  md:pt-1   px-4   content-center flex-grow w-full z-40 top-0    md:max-h-14 justify-start md:justify-center py-4 "
            >
                <div className='flex content-center w-full max-w-screen-xl md:px-10 justify-space'>
                    <div className="flex flex-wrap items-center w-full md:px-10">

                        <div className="flex w-1/2 pt-2 items-center   text-xs md:text-base">

                            <ul className="list-reset flex justify-between flex-1 md:flex-none items-center">

                                <li className=" md:flex-none mr-10">
                                    <div className="relative inline-block md:text-right cursor-pointer"  >
                                        <div>
                                            <a href="/"
                                                className="inline-flex justify-center w-full  cursor-pointer     text-xs md:text-sm font-medium text-white  "
                                            >
                                                <img style={{ maxHeight: "25px" }} src={tf} />
                                            </a>
                                        </div>

                                    </div>

                                </li>

                                <li className="hidden md:flex md:flex-none mr-5">
                                    <div className="relative inline-block md:text-right"  >
                                        <div>
                                            <div
                                                className="inline-flex justify-center w-full cursor-pointer   py-2  text-xs md:text-sm font-medium text-white  "

                                            >
                                                <a className='' href="/">Main</a>

                                            </div>
                                        </div>

                                    </div>

                                </li>
                                <li className="hidden md:flex md:flex-none mr-5">
                                    <div className="relative inline-block md:text-right"  >
                                        <div>
                                            <div
                                                className="inline-flex justify-center w-full cursor-pointer   py-2  text-xs md:text-sm font-medium text-white  "

                                            >
                                                <a className='' href="/pages/features">Features</a>

                                            </div>
                                        </div>

                                    </div>

                                </li>

                                <li className=" hidden md:flex md:flex-none mr-5">
                                    <div className="relative inline-block md:text-right"  >
                                        <div>
                                            <div
                                                className="inline-flex justify-center w-full  cursor-pointer  py-2  text-xs md:text-sm font-medium text-white  "

                                            >
                                                <a className='' href="/#plans">Plans</a>
                                            </div>
                                        </div>

                                    </div>

                                </li>


                            </ul>

                        </div>
                        <div className="flex w-1/2 pt-2 md:content-center   text-xs md:text-base md:justify-end">
                            <ul className="list-reset flex justify-end items-end flex-1 md:flex-none md:items-center">



                                {/* <li className=" md:flex-none ml-5 mr-5 ">
                                    <div className="relative inline-block text-left"  >
                                        <div>
                                            <div
                                                className="inline-flex justify-center w-full py-2 cursor-pointer text-xs md:text-sm font-medium text-white  "

                                            >
                                                <a className='' href="/#plans">Plans</a>
                                            </div>
                                        </div>

                                    </div>

                                </li> */}
                                <li className=" md:flex-none md:ml-5">
                                    <div className="relative inline-block text-left" id="left-menu-button">
                                        <div>
                                            <div
                                                className="inline-flex justify-center w-full py-2  cursor-pointer text-xs md:text-sm font-medium text-white underline underline-offset-8"

                                            >
                                                <a className='' href="/login">Log in or Sign up</a>

                                            </div>
                                        </div>

                                    </div>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>

            <div className="flex flex-column   justify-center flex-grow " >

                <div className=" w-full flex   flex-col ">
                    <PromoCodeBlock />
                    {/* <div className="flex w-full  p-7 justify-center items-center">
                        <h1 className="text-xl text-center">iCanHelp.ai</h1>
                    </div> */}

                    <div className=" flex flex-col md:flex-row    w-full justify-center h-fit">

                        <div className="max-w-screen-xl text-center flex flex-row flex-wrap w-full h-fit justify-center p-2 md:p-10 md:pb-2 md:pt-2">
                            <div className=" p-7 md:p-10 pb-0 md:pb-0  flex items-center flex-col ">
                                <h2 className=' text-4xl font-sans w-full'>TalkFlow’s AI Assistant&nbsp;<span style={{ "zIndex": "10", "display": "inline-block" }} className="shadow_h"> can hear, see and code</span></h2>
                                <p className="   text-xl  mt-5 mb-5 font-mono md:w-2/3">Smart AI that gives real-time advice during conversations and helps to solve coding problems
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className=" w-full flex bg-white z-40 flex-col sticky top-0">
                        <div className=" flex flex-col md:flex-row  bg-white    w-full justify-center h-fit ">

                            <div className="max-w-screen-xl text-center flex flex-row flex-wrap w-full h-fit  p-2 md:p-10 md:pb-2 md:pt-2 justify-center">
                                <div className="flex flex-row justify-center md:w-1/3">
                                    <div className="flex flex-row justify-center h-full">
                                        <span className="ml-3 text-xl font-medium text-gray-900 dark:text-gray-300 inline-flex items-center mr-3">Personal</span>
                                        <label htmlFor="toggle" className="relative inline-flex items-center cursor-pointer text-xl">
                                            <input type="checkbox" className="sr-only peer" id="toggle" />
                                            <div className="w-11 h-6 bg-teal-400 peer-focus:outline-none ring-blue-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[4px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-400 items-center">
                                            </div>
                                            <span className="ml-3 font-medium text-gray-900 dark:text-gray-300">Enterprise</span>
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className=" flex flex-col md:flex-row    w-full justify-center h-fit bg-crosses">
                        <div className="max-w-screen-xl   flex flex-row flex-wrap w-full h-fit ">
                            <div className="w-full    p-2 md:p-10   md:pt-0 h-fit ">
                                <div style={{ position: 'relative' }} className='w-full h-full'>
                                    <div style={{ position: 'absolute', top: '4px', left: '4px', width: '10px', height: '10px', borderLeft: '1px solid black', borderTop: '1px solid black' }}></div>
                                    <div style={{ position: 'absolute', top: '4px', right: '4px', width: '10px', height: '10px', borderRight: '1px solid black', borderTop: '1px solid black' }}></div>
                                    <div style={{ position: 'absolute', bottom: '4px', left: '4px', width: '10px', height: '10px', borderLeft: '1px solid black', borderBottom: '1px solid black' }}></div>
                                    <div style={{ position: 'absolute', bottom: '4px', right: '4px', width: '10px', height: '10px', borderRight: '1px solid black', borderBottom: '1px solid black' }}></div>



                                    {/* two columns */}
                                    <div className=" p-2 md:p-5    flex flex-col md:flex-col  ">
                                        <div className="p-5 w-full flex  flex-col md:flex-row ">
                                            <div className="flex flex-col justify-center h-full pb-6 md:pb-0 md:w-1/2 md:pr-8">
                                                <p className='flex personal'>Talkflow App for your PC is listening quietly in the background and ready to provide AI advice when you need it.</p>
                                                <p className='flex enterprise'>Talkflow App for your business can move your verbal conversations with customers and candidates to another level. Save time on personnel training, improve speed and move transparency to the next level.</p>

                                                <div className='mt-4 flex flex-col md:flex-row items-center'>
                                                    <p className='flex md:w-4/5 justify-center'>Now with AI vision that can capture your coding task</p>
                                                    <div className='flex md:w-1/5    ' ><img className='  w-16 drop-shadow-lg  ' src={vision} /></div>

                                                </div>
                                            </div>
                                            <div className="flex flex-col justify-center h-full md:w-1/2 ease">
                                                <ul className='font-sans personal'>

                                                    <li className='pb-3 personal'><h3 className='font-sans   mr-1 text-xl font-bold personal '>During online job interview</h3> Gives answers on hard and soft skills questions, writes code and explains  </li>
                                                    <li className='pb-3 personal'><h3 className='font-sans   mr-1 text-xl font-bold personal'>Your little AI secretary</h3> Produces minutes of the call, action plan and detailed transcript</li>
                                                </ul>
                                                <ul className='font-sans enterprise'>
                                                    <li className='pb-3 enterprise'><h3 className='font-sans   mr-1 text-xl font-bold enterprise'>AI Recruiter</h3>Empower your screening interview with deeper technological questions and automatically integrated reports</li>
                                                    <li className='pb-3 enterprise'><h3 className='font-sans   mr-1 text-xl font-bold enterprise'>Sales supporter</h3> Suggests good pitch, can integrate with your company's sales and help to follow them</li>
                                                    <li className='pb-3 enterprise'><h3 className='font-sans   mr-1 text-xl font-bold enterprise'>Supercharged helpdesk</h3> Gives a answers on users requests of any complexity using corporate knowledge bases and guides</li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="p-5 flex w-full    flex-col  justify-around items-center content-center">
                                    {/* <img className="w-full md:max-w-4xl h-full drop-shadow-2xl " src={screenshot_ns} /> */}
                                    <img className="w-72 mb-6" src={os} />
                                    <video src={video}
                                        className="w-screen  video_demo  md:max-w-4xl h-full  drop-shadow-2xl"
                                        id="myVideo1"
                                        muted
                                        autoPlay
                                        playsInline
                                        // ratio={videoRatio}
                                        loop
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ backgroundImage: `url(images/osc.svg)`, backgroundSize: "cover", }} className='-mt-16  md:-mt-44 flex w-full h-96 justify-center   align-middle  items-center mb-2 flex-col md:flex-row '>
                        <div className='flex flex-col md:flex-row '><a
                            className="flex px-6 h-16 w-64 text-xl uppercase leading-7	bg-teal-400 align-middle content-center items-center justify-center tracking-wider border-2 border-slate-950  text-slate-950 shadow-sm hover:shadow-lg shadow-slate-950/50 md:mr-10 md:mt-10 "
                            href="/login"    >Get Started</a>

                            <a
                                className="flex px-6 h-16 w-64 text-xl uppercase leading-7	  align-middle content-center items-center justify-center tracking-wider border-2 border-slate-950  text-slate-950 shadow-sm hover:shadow-lg shadow-slate-950/50  bg-white    mt-10 "
                                href="/#plans"    >See all plans</a>
                        </div>


                    </div>
                    <div style={{ backgroundImage: `url(images/bg.svg)`, backgroundSize: "cover", }}>
                        <div className=" flex flex-col md:flex-row    w-full justify-center h-fit">
                            <div className=" max-w-screen-xl p-2  flex flex-row flex-wrap w-full h-fit ">
                                <div className="w-full     p-2 md:p-10 h-fit flex flex-col md:flex-row ">
                                    {/* two columns */}



                                    <div className="w-full   md:w-1/2 flex justify-items-end opacity-70 md:opacity-100  ">

                                        <div className="w-full" id='sound-icon'>
                                            <div className="w-full" id='sound-wave'>
                                                <i className='bar'></i>
                                                <i className='bar'></i>
                                                <i className='bar'></i>
                                                <i className='bar'></i>
                                                <i className='bar'></i>
                                                <i className='bar'></i>
                                                <i className='bar'></i>
                                                <i className='bar'></i>
                                                <i className='bar'></i>
                                                <i className='bar'></i>
                                                <i className='bar'></i>
                                                <i className='bar'></i>
                                                <i className='bar'></i>
                                                <i className='bar'></i>
                                                <i className='bar'></i>
                                                <i className='bar'></i>
                                                <i className='bar'></i>
                                                <i className='bar'></i>
                                                <i className='bar'></i>
                                                <i className='bar'></i>
                                                <i className='hidden-mobile bar'></i>
                                                <i className='hidden-mobile bar'></i>
                                                <i className='hidden-mobile bar'></i>
                                                <i className='hidden-mobile bar'></i>
                                                <i className='hidden-mobile bar'></i>
                                                <i className='hidden-mobile bar'></i>
                                                <i className='hidden-mobile bar'></i>
                                                <i className='hidden-mobile bar'></i>
                                                <i className='hidden-mobile bar'></i>
                                                <i className='hidden-mobile bar'></i>
                                                <i className='hidden-mobile bar'></i>
                                                <i className='hidden-mobile bar'></i>
                                                <i className='hidden-mobile bar'></i>
                                                <i className='hidden-mobile bar'></i>
                                                <i className='hidden-mobile bar'></i>
                                                <i className='hidden-mobile bar'></i>
                                                <i className='hidden-mobile bar'></i>
                                                <i className='hidden-mobile bar'></i>
                                                <i className='hidden-mobile bar'></i>
                                                <i className='hidden-mobile bar'></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex w-full md:w-1/2 h-32 items-center justify-items-space justify-around md:pl-10 -mt-28 md:mt-0">
                                        <div style={{ position: 'relative', textAlign: "center", verticalAlign: "middle" }} className='w-full h-full'>
                                            <div id="spin" className="text-xl mx-auto"></div>
                                            <div style={{ position: 'absolute', top: '4px', left: '4px', width: '10px', height: '10px', borderLeft: '1px solid black', borderTop: '1px solid black' }}></div>
                                            <div style={{ position: 'absolute', top: '4px', right: '4px', width: '10px', height: '10px', borderRight: '1px solid black', borderTop: '1px solid black' }}></div>
                                            <div style={{ position: 'absolute', bottom: '4px', left: '4px', width: '10px', height: '10px', borderLeft: '1px solid black', borderBottom: '1px solid black' }}></div>
                                            <div style={{ position: 'absolute', bottom: '4px', right: '4px', width: '10px', height: '10px', borderRight: '1px solid black', borderBottom: '1px solid black' }}></div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="strpedBg" className=" flex flex-col md:flex-row  w-full justify-center shadow-lg ">
                            <div className=" max-w-screen-xl   p-2 flex flex-row flex-wrap w-full   ">

                                <div className="w-full     p-2 md:p-10 h-fit   md:py-12">


                                    <div className='flex flex-col md:flex-row  bg-slate-950 text-white shadow-lg'>


                                        <div className="flex  md:w-2/5 w-full  justify-items-end p-10 pb-0 md:pb-10 ">


                                            <div className="flex flex-col justify-center h-auto   items-center md:items-start w-full">


                                                <div style={{ position: 'relative', maxHeight: "400px" }} className='w-full h-full'>

                                                    <div style={{ minHeight: '350px' }} className="flex flex-col justify-center min-h-60	h-full">
                                                        <div className='gradienText'><h2 className='text-4xl p-5 font-bold font-sans'>TalkFlow AI features for your conversation. </h2>
                                                            <h2 className=" text-4xl p-5 pt-2 font-bold font-sans">Not only hearing, but understanding.</h2>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>




                                        <div className="   md:w-3/5 w-full  justify-items-end p-10  pr-20   ">
                                            <div style={{ position: 'relative', verticalAlign: "middle" }} className='  font-sans w-full h-full'>

                                                <ul className='mt-4 w-full personal '>

                                                    <li className='pb-6  '><h3 className='font-sans   mr-1  text-xl font-bold'>Real-Time Question   Answering</h3> Utilizing the power of OpenAI's GPT, our AI can suggest responses during stresfull interviews or work calls.</li>
                                                    <li className='pb-6  '><h3 className='font-sans   mr-1 text-xl font-bold'>Code writing</h3> Somebody asks you to write or review code? Our app captures content of the screen to propose solution in almost every programming language.</li>
                                                    <li className='pb-6  '><h3 className='font-sans   mr-1 text-xl font-bold'>Save your call and reflect on it</h3> Recieve AI-generated feedback on your perfomance during interview, get suggestions on how which hard and soft skill you should improve to master next interview. </li>


                                                </ul>

                                                <ul className='mt-4 w-full enterprise '>

                                                    <li className='pb-6  '><h3 className='font-sans   mr-1 text-xl font-bold'>Voice Recognition</h3> Our AI isn't just good at understanding spoken language—it excels at it, ensuring every word is captured accurately. You are having a forever access to a searchable meeting content.</li>
                                                    <li className='pb-6  '><h3 className='font-sans   mr-1 text-xl font-bold'>Speaker Diarization</h3> It also identifies individual speakers, so you know who said what, providing clarity in group discussions.</li>
                                                    <li className='pb-6  '><h3 className='font-sans   mr-1 text-xl  font-bold'>Meeting Summaries</h3> Why trawl through pages of transcript when you can get a concise summary? Keep managers and teams followed-up with results, ideas, issues and tasks discussed.</li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" flex flex-col md:flex-row    w-full justify-center h-fit">

                        <div className="max-w-screen-xl  p-2 flex flex-col flex-wrap w-full h-fit justify-center place-content-center">
                            <div className='flex  w-full h-fit flex-col justify-center'>
                                <h2 className='ml-10 mt-10 text-4xl font-sans  '>How it works<span style={{ "zIndex": "1" }} className="shadow_h"></span></h2>
                                <div style={{ "textAlign": "center" }} className="flex flex-col w-full place-content-around ">
                                    <div className="   w-10/12 md:w-8/12   mt-10  m-auto  h-fit personal ">
                                        <div className="video-responsive drop-shadow-2xl">
                                            <iframe
                                                width="853"
                                                height="480"
                                                src={`https://www.youtube.com/embed/kOnXWA_-Gd8`}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                                title="Embedded youtube"
                                            />
                                        </div>
                                    </div>


                                    <div style={{ "height": "0" }} className="w-10/12 md:w-8/12   mt-10  m-auto h-fit enterprise ">
                                        <div className="video-responsive drop-shadow-2xl">
                                            <iframe
                                                width="853"
                                                height="480"
                                                src={`https://www.youtube.com/embed/4fzkyP4X94E`}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                                title="Embedded youtube"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className=" flex flex-col md:flex-row    w-full justify-center h-fit ">
                        <div className="   flex flex-row flex-wrap w-full h-fit ">
                            <div className="w-full      h-fit ">
                                {/* two columns */}
                                <div className="      flex flex-col md:flex-row ">
                                    {/* <div    className="   mix-blend-darken border-black w-fit   flex grow  "/> */}
                                    <div style={{ "backgroundColor": "rgba(255 255 255 / 0.4)" }} className='w-full justify-center flex'>
                                        <div className='max-w-screen-xl   flex w-full p-10 md:pb-0'>

                                            <div className="p-5   w-full  ">
                                                <div className="flex flex-col justify-center h-full md:w-3/4">
                                                    <h2 className='text-4xl font-sans'>Understanding <span style={{ "zIndex": "1" }} className="shadow_h"> billions</span>, Made for You</h2>
                                                    <p className=" font-sans   mt-4">TalkFlow boasts an impressive multilingual capability, supporting a wide range of languages including<br /><span className='font-mono flags'>{emoji("🇺🇸🇬🇧 English, 🇪🇸 Spanish, 🇨🇳 Mandarin Chinese, 🇮🇳 Hindi, 🇵🇹 Portuguese, 🇷🇺 Russian, 🇯🇵 Japanese, 🇹🇷 Turkish, 🇰🇷 Korean, 🇫🇷 French, 🇩🇪 German, 🇻🇳 Vietnamese, 🇮🇹 Italian, 🇸🇦 Arabic, 🇵🇱 Polish, 🇨🇿 Czech, 🇷🇴 Romanian, 🇮🇱 Hebrew, 🇫🇮 Finnish.")}</span></p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div></div>
                        </div>
                    </div>




                    <div id="web" className=" flex flex-col md:flex-row    w-full justify-center h-fit">

                        <div className="max-w-screen-xl  p-2 flex flex-row flex-wrap w-full h-fit ">

                            <div className="w-full   mt-10 p-2 md:px-10 h-fit md:pb-0">

                                {/* two columns */}
                                <div className="    flex flex-col-reverse md:flex-row ">
                                    <div className={" p-5 md:p-5   flex w-full md:w-1/2 mt-10 h-fit  justify-around items-center content-center py-0 "}>

                                        <img className=" h-full drop-shadow-2xl" src={gifka} />
                                    </div>
                                    <div className="p-5 flex w-full md:w-1/2      justify-around  py-0 flex-row" >
                                        {/* <img className=" h-full " src={i1} /> */}

                                        <div className={' w-full font-sans mt-4  mb-3'}><h2 className=' text-4xl font-sans'>Web Features</h2>
                                            <div className='personal'>
                                                <ul className='mt-4 w-full font-mono h-full bluechek pl-3'>
                                                    <li className='pb-3 p-3  ' >Live listening and real-time transcription of your conversations, offering answers, writing code on demand, and explaining mentioned terms.</li>
                                                    <li className='pb-3 p-3  '>Saves recordings of your conversations, produces transcripts, and prepares suggestions.</li>
                                                    <li className='pb-3 p-3   '>Allows you to upload recordings of any of your online or offline conversations and receive a transcript with identified speakers, along with an AI-produced summary of the call. This summary includes meeting minutes, an action plan, and more.</li>
                                                    <li className='pb-3  p-3  '>Utilizes the embedded ChatGPT to interact with your conversations: it can answer questions, make suggestions, and perform analyses.</li>
                                                </ul>

                                            </div>
                                            <div className='enterprise'>
                                                <ul className='mt-4 w-full font-mono h-full  bluechek pl-3'>
                                                    <li className='p-3 ' >Provides live listening and real-time transcription of your employees' online conversations, offering suggestions and support during interactions with clients, colleagues, or candidates.</li>
                                                    <li className='p-3 '>Employs your corporate knowledge or custom data for answering questions.</li>
                                                    <li className='p-3 '>Automatically converts conversation recordings to meeting minutes and follow-ups for increased transparency.</li>
                                                    <li className='p-3 '>Utilizes the embedded ChatGPT to interact with your conversations: it can answer questions, make suggestions, and perform analyses. This is not limited to single conversations, but extends across team conversations or even the entire organization.</li>
                                                </ul>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>




                    <div id="plans" className="mt-12    flex flex-col      w-full justify-center bg-crosses items-center">
                        <div className=" max-w-screen-xl  p-4 md:px-10 flex flex-row flex-wrap h-full w-full justify-center personal ">
                            <h2 className='text-4xl font-sans p-5 pb-0'  ><span style={{ "zIndex": "20" }} className="shadow_h">Flexible</span> set of plans</h2>

                            <div className="w-full      p-2 md:p-10 h-fit flex flex-col md:flex-row pt-0 md:pt-0 ">


                                <div className="   xl:w-1/3 w-full  justify-items-end      md:py-4">

                                    <div className="flex h-screen rounded-lg bg-white     flex-col   h-full pb-10 ">
                                        <h3 className='text-xl font-mono flex mt-8 justify-center'>Starter subscription</h3>
                                        <p className='pt-4 font-sans text-sm px-10 text-center'>
                                            7 days free trial! Great flexible option to try out TalkFlow and see how it works for you.
                                        </p>
                                        <ul className={' w-full font-sans mt-4 pr-10 pl-10 mb-3'}>
                                            <li className='pb-3'>✅ &nbsp;<strong className='font-mono   mr-1'>7 days</strong> free trial!</li>

                                            <li className='pb-3'>✅ &nbsp;<strong className='font-mono   mr-1'>1,5 hours</strong> of meeting live assistance for a week</li>

                                            <li className='pb-3'>✅ &nbsp;<strong className='font-mono   mr-1'>3 hours</strong> of meeting transcription uploads weekly</li>
                                            <li className='pb-3'>✅ &nbsp;<strong className='font-mono mr-1'>AI meeting notes</strong> for uploaded meeting recordings</li>
                                            <li className='pb-3'>✅ &nbsp;<strong className='font-mono   mr-1'>History</strong> of transcripts</li>
                                            <li className='pb-3'>✅ &nbsp;<strong className='font-mono   mr-1'>Unlimited</strong> download of your uploaded and live transcripts</li>


                                        </ul>
                                        <div className={' w-full font-sans mt-4 pr-10 pl-10 mb-3'}>

                                            <p className="text-sm"> <span className="inline text-3xl  font-bold    mr-3  float-left">$5</span> per user<br />per week/billed weekly
                                            </p>

                                        </div>

                                        <button
                                            className=" mt-10 mx-4 md:mx-20 h-8 uppercase  tracking-wider border-2 border-black bg-teal-400 text-black hover:shadow-lg hover:shadow-teal-500/50"
                                            id="toggleBtn" onClick={() => navigate('/login')}>Sign up</button>

                                    </div>

                                </div>

                                <div className="   xl:w-1/3 w-full  justify-items-end  z-30  md:py-2">

                                    <div className="flex h-screen   flex-col rounded-lg  bg-white shadow-xl h-full pb-10">
                                        <h3 className='text-xl font-mono flex mt-8 justify-center'>Basic subscription</h3>
                                        <p className='pt-4 font-sans text-sm px-10 text-center'>
                                            If you want always available AI assistant for your online conversations.
                                        </p>
                                        <ul className={' w-full font-sans mt-4 pr-10 pl-10 mb-3 '}>
                                            <li className='pb-3'>😊 &nbsp;<strong className='font-mono   mr-1'>10 hours</strong> of meeting live assistance</li>
                                            <li className='pb-3'>😊 &nbsp;<strong className='font-mono   mr-1'>20 hours</strong> of meeting transcription monthly</li>

                                            <li className='pb-3'>✅ &nbsp;<strong className='font-mono mr-1'>AI meeting notes</strong> for uploaded meeting recordings</li>
                                            <li className='pb-3'>✅ &nbsp;<strong className='font-mono   mr-1'>History</strong> of transcripts</li>
                                            <li className='pb-3'>✅ &nbsp;<strong className='font-mono   mr-1'>Unlimited</strong> download of your uploaded and live transcripts</li>



                                        </ul>
                                        <div className={' w-full font-sans mt-4 pr-10 pl-10 mb-3'}>

                                            <p className="text-sm"> <span className="inline text-3xl  font-bold  mr-3  float-left">$20</span> per user<br />per month/billed monthly
                                            </p>

                                        </div>

                                        <button
                                            className=" mt-10 mx-4 md:mx-20 h-8 uppercase  tracking-wider border-2 border-black bg-teal-400 text-black hover:shadow-lg hover:shadow-teal-500/50"
                                            id="toggleBtn" onClick={() => navigate('/login')}>Sign up</button>

                                    </div>
                                </div>

                                <div className="   xl:w-1/3 w-full  justify-items-end    md:py-4">

                                    <div className="flex h-screen  flex-col rounded-lg  bg-white   h-full pb-10">
                                        <h3 className='text-xl font-mono flex mt-8 justify-center'>Pro subscription</h3>
                                        <p className='pt-4 font-sans text-sm px-10 text-center'>
                                            For those who spend a lot of time on online meetings.
                                        </p>
                                        <ul className={' w-full font-sans mt-4 pr-10 pl-10 mb-3 '}>
                                            <li className='pb-3'>😊 &nbsp;<strong className='font-mono   mr-1'>25 hours</strong> of meeting live assistance</li>
                                            <li className='pb-3'>😊 &nbsp;<strong className='font-mono   mr-1'>40 hours</strong> of meeting transcription monthly</li>

                                            <li className='pb-3'>✅ &nbsp;<strong className='font-mono mr-1'>AI meeting notes</strong> for uploaded meeting recordings</li>
                                            <li className='pb-3'>✅ &nbsp;<strong className='font-mono   mr-1'>History</strong> of transcripts</li>
                                            <li className='pb-3'>✅ &nbsp;<strong className='font-mono   mr-1'>Unlimited</strong> download of your uploaded and live transcripts</li>


                                        </ul>
                                        <div className={' w-full font-sans mt-4 pr-10 pl-10 mb-3'}>

                                            <p className="text-sm"> <span className="inline text-3xl  font-bold  mr-3  float-left">$35</span> per user<br />per month/billed monthly
                                            </p>

                                        </div>

                                        <button
                                            className=" mt-10 mx-4 md:mx-20 h-8 uppercase  tracking-wider border-2 border-black bg-teal-400 text-black hover:shadow-lg hover:shadow-teal-500/50"
                                            id="toggleBtn" onClick={() => navigate('/login')}>Sign up</button>

                                    </div>
                                </div>





                            </div>

                        </div>
                        <div className=" max-w-screen-xl  p-4 md:px-10 flex flex-row flex-wrap h-full w-full  enterprise ">

                            <div className="w-full      p-2 md:p-10 h-fit flex flex-col md:flex-row pt-0 md:pt-0 enterprise">
                                <div className="     w-full  justify-items-end p-2   md:px-10 md:py-8">
                                    <h2 className='text-4xl font-sans p-5' >Contact us: <a href="mailto:contact@talkflow.app">contact@talkflow.app</a></h2>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <footer className="bg-slate-950 text-white text-center p-4 mt-8">
                {/* Footer content goes here */}
                <div style={{ "margin": "auto" }} className='flex max-w-screen-xl py-5 flex-col  md:flex-row p-4 px-20 md:px-30' >
                    <div className="   z-30  flex flex-col flex-wrap h-full w-full align-end ">
                        {links.map((link) => <div className='z-40 cursor-pointer flex'><a className='cursor-pointer' href={"/pages/" + link.slur}>{link.title}</a></div>)}
                    </div>
                    <div className='flex flex-col mt-3 md:mt-0'>
                        <div>
                            <img className='flex' style={{ maxHeight: "25px", opacity: 0.9 }} src={tf} />

                        </div>
                        <div>
                            <a className="flex text-left" href="mailto:contact@talkflow.app">contact@talkflow.app</a>


                        </div>

                    </div>
                </div>

            </footer>
        </div>
    );
}

export default LandingPage;
