import React from 'react';
 
const Header = (props) => {
    return (
        <div className="p-7" >
           

            <div style={{ position: 'relative' }} className='pl-4 flex text-center text-base mt-5 '><h1 className='text-xl'>{props.name}</h1>
                <div style={{ position: 'absolute', top: '-4px', left: '-4px', width: '5px', height: '5px', borderLeft: '1px solid black', borderTop: '1px solid black' }}></div>
                <div style={{ position: 'absolute', top: '-4px', right: '-4px', width: '5px', height: '5px', borderRight: '1px solid black', borderTop: '1px solid black' }}></div>
                <div style={{ position: 'absolute', bottom: '-4px', left: '-4px', width: '5px', height: '5px', borderLeft: '1px solid black', borderBottom: '1px solid black' }}></div>
                <div style={{ position: 'absolute', bottom: '-4px', right: '-4px', width: '5px', height: '5px', borderRight: '1px solid black', borderBottom: '1px solid black' }}></div>
            </div>
        </div>
    )
}
export default Header;