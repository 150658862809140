import {   useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
 
const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    useEffect(()=>{
        if (!userInfo || !userInfo.jwt ) {
            navigate('/');
             
          }

    })


  return userInfo&&children;
};

export default PrivateRoute;
