 import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from './pages/LoginPage';
import LivePage from './pages/Live';
import { GoogleOAuthProvider } from '@react-oauth/google';
import PrivateRoute from './pages/components/PrivateRoute';
import LandingPage from './landing/Main';
import Page from './landing/Page';
import ProductCheckoutComponent from './pages/Subscription';
import './App.css';
import FromFiles from './pages/FromFiles';
import React, { useEffect } from 'react';
//eslint-disable-next-line
import CookieConsent, { Cookies } from "react-cookie-consent";
// import ReactGA from 'react-ga';

function App() {
  useEffect(() => {
    // Check if the user has already given their consent to the cookie usage
    // const hasUserGivenConsent = Cookies.get("myAwesomeCookieName2") === "true";

    // if (hasUserGivenConsent) {
      // Initialize Google Analytics
      // ReactGA.initialize('G-V5X17SP9N3');
      // ReactGA.pageview(window.location.pathname + window.location.search);
    // }
  }, []);
  return (
<>

<CookieConsent
        location="bottom"
        buttonText="I accept"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150} // this is in days
        onAccept={() => {
          // Initialize Google Analytics after acceptance
          // ReactGA.initialize('G-V5X17SP9N3');
          // ReactGA.pageview(window.location.pathname + window.location.search);
        }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>


    <GoogleOAuthProvider clientId='318276156218-shds1gau8qld425e6tlrvljk88k85qrf.apps.googleusercontent.com'>
  <Router>
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/live" element={<PrivateRoute><LivePage /></PrivateRoute>} />
      <Route path="/fromfiles" element={<PrivateRoute><FromFiles /></PrivateRoute>} />
      <Route path="/subscription" element={<PrivateRoute><ProductCheckoutComponent /></PrivateRoute>} />
      <Route path="/pages/:pagename" element={<Page />} />
      <Route path="/Reddit" element={<LandingPage />} />
      <Route path="/TikTok" element={<LandingPage />} />
      <Route path="/Instagram" element={<LandingPage />} />
      <Route path="/ProductHunt" element={<LandingPage />} />
      <Route path="/LinkedIn" element={<LandingPage />} />
      <Route path="/" element={<LandingPage />} />
      
    </Routes>
  </Router>
    </GoogleOAuthProvider>
    </>
  );
}

export default App;



 