import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import Conversation from './Conversation';

import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
 

 
function parseTimecodeToSeconds(timecode) {
    const [hours, minutes, seconds] = timecode.split(':').map(Number);
    return (hours * 60 * 60) + (minutes * 60) + seconds;
  }
  
  // Function to update the transcript
  function injectImagesIntoTranscript(transcript, slides) {
    if (!slides || slides.length === 0) return transcript;
    // Split the transcript into sections based on speaker names
    const sections = transcript.split("\n## ");
  
    // Create an array to hold the blocks of text and images
    let blocks = [];
  
    // Iterate over each section
    sections.forEach(section => {
      const lines = section.split("\n");
      let time;
      let content = '';
  
      // First line is assumed to be the speaker name
      const speakerName = lines.shift();
  
      // Iterate over the lines to create text blocks
      lines.forEach(line => {
        const timecodeMatch = line.match(/\[(\d{2}:\d{2}:\d{2}) - (\d{2}:\d{2}:\d{2})\]/);
        if (timecodeMatch) {
          time = parseTimecodeToSeconds(timecodeMatch[2]);
        }
        content += '\n' + line;
      });
  
      blocks.push({
        type: 'text',
        time,
        content: `## ${speakerName}${content}`,
      });
    });
  
    // Map the slides to have similar structure
    const imageBlocks = slides.map(slide => ({
      type: 'image',
      time: slide.timecode,
      content: `![Slide ${slide.id}](${slide.file})`
    }));
  
    // Merge the blocks and images
    blocks = blocks.concat(imageBlocks);
  
    // Sort by time, moving blocks without time to the start
    blocks.sort((a, b) => {
      if (a.time === null) return -1;
      if (b.time === null) return 1;
      return a.time - b.time;
    });
  
    // Combine all the blocks back into a transcript
    const updatedTranscript = blocks.map(block => block.content).join("\n\n");
    
    return updatedTranscript;
  }

function removeOwlParagraphs(text) {
    const owlRegex = /🦉[^]*?\n\n/g; // Matches the owl emoji followed by any characters until "\n\n" is encountered
    const owlRegex_ = /## 🦉[^]*?\n\n/g; // Matches the owl emoji followed by any characters until "\n\n" is encountered

    return text.replace(owlRegex_, '').replace(owlRegex, '');
}


function extractSpeakers(text) {
    const matches = text.match(/SPEAKER_\d\d/g);
    const uniqueMatches = [...new Set(matches)];
    uniqueMatches.sort();
    return uniqueMatches;
}

function formatSpeakerText(text) {
    // split the text by underscore

    // join the words back together with underscore
    return text[0].toUpperCase() + text.substr(1).toLowerCase();
}
function substituteTagsWithNames(text, tagNames) {
    // Check if tagNames is null or undefined
    if (!tagNames || !text) {
        return text;
    }

    tagNames.forEach(function (tagName) {
        // Check if name property is null, undefined, or an empty string
        if (!tagName.name) {
            return;
        }
        const tag = new RegExp(tagName.tag, 'g');
        const tag_ = new RegExp(formatSpeakerText(tagName.tag), 'g');
        text = text.replace(tag_, tagName.name)

        text = removeOwlParagraphs(text.replace(tag, tagName.name));
    });

    return text;
}


const ChatWithTranscript = ({ transcript, config }) => {
    const [transcriptLoading, setTranscriptLoading] = useState(false);
    const [messages, setMessages] = useState(transcript.Chat ? transcript.Chat : [{ "source": "speaker", "text": "ask questions about your conversation" }]);
    const [question, setQuestion] = useState(null);
    const chat_with_transcript = async (id, question) => {
        setQuestion(null);



        setMessages([...messages, { "source": "mic", "text": question },]);
        setTranscriptLoading(true);
        const resp = await axios.post(process.env.REACT_APP_API_URL + 'api/chat_with_transcript', { id: transcript.id, question }, config);
        setTranscriptLoading(false);
        setMessages(resp.data);
        var objDiv = document.getElementById("conversation");
        objDiv.scrollTop = objDiv.scrollHeight;
    }
    return (<>
        <div className="flex flex-row flex-grow align-middle items-center p-4">
            <input
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        chat_with_transcript(transcript.id, question);
                    }
                }}
                disabled={transcriptLoading}
                type="text" id="first_name" className="mr-2 bg-gray-50 border border-black text-gray-900 text-sm   focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500" placeholder="Ask your question or give a task" />
            {transcriptLoading ? <div className="custom-loader w-3 flex"></div> : <button
                onClick={() => { chat_with_transcript(transcript.id, question); }}
                disabled={transcriptLoading}
                className="flex w-3 content-around items-center justify-around px-6 h-8 uppercase tracking-wider border-1 border-black bg-teal-400 text-black hover:shadow-lg hover:shadow-teal-500/50 "
            >
                {" > "}
            </button>}
        </div>
        <div id="conversation" className="flex flex-col md:flex-row flex-grow">

            <Conversation messages={messages} />
        </div></>
    )
}
const SpeakerSetting = ({ transcript, config, updateTranscript }) => {
    let valuesFromServer = {};
    let cheks = {}
    const speakers = extractSpeakers(transcript.markdown);
    if (transcript.speakers.length === 0) {
        speakers && speakers.forEach(s => { console.log(s); valuesFromServer[s] = s; cheks[s] = true; });
    }
    else {
        transcript.speakers && transcript.speakers.forEach(s => { console.log(s); valuesFromServer[s.tag] = s.name; cheks[s.tag] = s.name && s.name.startsWith('🦉') ? false : true; });

    }
    const [loading, setLoading] = useState(false);
    const [checkboxState, setCheckboxState] = useState(cheks)
    const { handleSubmit, control, setValue, getValues } = useForm({
        defaultValues: valuesFromServer
    });

    useEffect(() => {
        transcript.speakers && transcript.speakers.forEach(s => setValue(s.tag, s.name));
    }, [transcript.speakers, setValue]);


    const saveChanges = newValues => {
        setLoading(true)
        var data = [];
        for (let prop in newValues) {
            data.push({ tag: prop, name: newValues[prop] });
        }
        axios.post(process.env.REACT_APP_API_URL + 'api/update-speakers', { UUID: transcript.UUID, speakers: data }, config)
            .then(response => {
                console.log(response.data);
                updateTranscript(response.data)

            })
            .catch(error => {
                console.error(error);
            }).finally(() => setLoading(false))
    };

    const onSubmit = saveChanges;

    return (<>
        <span>Enter names for recognized speakers or turn them off to remove them from transcription</span>
        <form className="mt-4 space-y-4" onSubmit={handleSubmit(onSubmit)}>
            {speakers.map((speaker, i) => (
                <div key={i} className="flex items-center space-x-2">
                    <input value={checkboxState[speaker]} checked={checkboxState[speaker]} onChange={(val) => { val.target.checked === true ? setValue(speaker, getValues(speaker).substring(2)) : setValue(speaker, '🦉' + getValues(speaker)); let obj = {}; obj[speaker] = val.target.checked; setCheckboxState({ ...checkboxState, ...obj }) }} type="checkbox" className=" checked:bg-teal-500 " />

                    <label htmlFor={speaker} className="text-base">{speaker}: </label>
                    <Controller
                        name={speaker}
                        control={control}
                        render={({ field }) => (
                            <input
                                {...field}
                                id={speaker}
                                type="text"
                                autoComplete="off"
                                disabled={loading || !checkboxState[speaker]}
                                className="border-2 border-black  px-2 py-1 w-64 focus:border-teal-500 focus:outline-none  disabled:bg-gray-400 "
                            />
                        )}
                    />
                </div>
            ))}
            {loading ? <div className="custom-loader flex"></div> : <button className=" px-6 h-8 uppercase text-sm tracking-wider border-2 border-black bg-teal-400 text-black hover:shadow-lg hover:shadow-teal-500/50" type="submit">Save</button>}
        </form></>
    )
};
const RenderedTranscript = (props) => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.jwt}`
        }
    }
    // var transcript = props.transcript;
    const [transcript, setObj] = useState(props.transcript);
    const [activeTab, setActiveTab] = useState('Transcript');
    const [slides, setSlides] = useState(transcript.slides);
    const [showProgress, setShowProgress] = useState(transcript.progress);
    const [transcriptMardown, setTranscript] = useState(transcript.markdown);
    // eslint-disable-next-line
    let intervalId;

    const getCurrentTranscript = async (id) => {


        var resp = await axios.get(process.env.REACT_APP_API_URL + 'api/fetch_transcription_by_UUID?UUID=' + id, config);
        // setTranscript(resp.data);
        setShowProgress(resp.data.progress)
        setTranscript(resp.data.markdown);
       

        if (resp.data.progress > 99) {

            // setShowProgress(false);  
            setObj(resp.data);

            clearInterval(intervalId)
        }


    }
    const downloadTxtFile = (type) => {
        const element = document.createElement("a");
        let fileContent = '';

        if (type === 'Transcript') {
            fileContent = substituteTagsWithNames(transcript.text, transcript.speakers)
        } else {
            fileContent = substituteTagsWithNames(transcript.minutes, transcript.speakers)
        }

        const file = new Blob([fileContent], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = type + ".txt";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }


    const TabContent = (props) => {

        if (transcript.slides && transcript.slides.length >0 && props.transcriptMardown  && activeTab === 'Transcript') {
            return <div id="transcript"><ReactMarkdown>{injectImagesIntoTranscript(substituteTagsWithNames(props.transcriptMardown, transcript.speakers),props.slides)}</ReactMarkdown></div>
        
        }
        if (activeTab === 'Transcript') {
            return <div id="transcript"><ReactMarkdown>{substituteTagsWithNames(props.transcriptMardown, transcript.speakers)}</ReactMarkdown></div>
        } else if (activeTab === 'Minutes') {
            return <pre className='fixedpre'>{substituteTagsWithNames(transcript.minutes, transcript.speakers)}</pre>
        } else if (activeTab === 'Speakers') {

            return <SpeakerSetting transcript={transcript} config={config} updateTranscript={props.updateTranscript} />
        } else if (activeTab === 'Chat') {

            return <ChatWithTranscript transcript={transcript} config={config} />
        }
    }

    useEffect(() => {
        setObj(props.transcript)
        setTranscript(props.transcript.markdown);
        setShowProgress(props.transcript.progress);
        setSlides(props.transcript.slides);
        if (props.transcript.progress < 99 && props.transcript.progress !== null) {

            setShowProgress(props.transcript.progress);
            // eslint-disable-next-line
            intervalId = setInterval(() => {
                getCurrentTranscript(props.transcript.UUID)
            }, 10000)
            return () => {
                clearInterval(intervalId);
            }
        }
    }, [props.transcript])

    return (
        <>
            {transcript.UUID ? (
                (true) ? <>
                    <div className='flex flex-row'>

                        <div className="px-6  ">
                            <nav className="flex space-x-2" aria-label="Tabs" role="tablist">
                                <button
                                    type="button"
                                    className={(activeTab === 'Transcript' ? ` font-semibold border-teal-600 border text-teal-600` : ' underline text-underline underline-offset-8 ') + `   px-1 inline-flex items-center gap-2 border-b-[3px] border-transparent text-sm whitespace-nowrap  hover:text-teal-600 ${activeTab === 'Transcript' ? 'hs-tab-active' : ''}`}
                                    onClick={() => setActiveTab('Transcript')}
                                >
                                    Transcript
                                </button>
                                {showProgress > 98 && <>
                                    <button
                                        type="button"
                                        className={(activeTab === 'Minutes' ? ` font-semibold border-teal-600  text-teal-600` : ' underline text-underline underline-offset-8 ') + `   px-1 inline-flex items-center gap-2 border-b-[3px] border-transparent text-sm whitespace-nowrap  hover:text-teal-600 ${activeTab === 'Minutes' ? 'hs-tab-active' : ''}`}
                                        onClick={() => setActiveTab('Minutes')}
                                    >
                                        Minutes
                                    </button>
                                    <button
                                        type="button"
                                        className={(activeTab === 'Speakers' ? ` font-semibold border-teal-600  text-teal-600` : ' underline text-underline underline-offset-8 ') + `   px-1 inline-flex items-center gap-2 border-b-[3px] border-transparent text-sm whitespace-nowrap  hover:text-teal-600 ${activeTab === 'Minutes' ? 'hs-tab-active' : ''}`}
                                        onClick={() => setActiveTab('Speakers')}
                                    >
                                        Speakers
                                    </button>
                                    <button
                                        type="button"
                                        className={(activeTab === 'Chat' ? ` font-semibold border-teal-600  text-teal-600` : ' underline text-underline underline-offset-8 ') + `   px-1 inline-flex items-center gap-2 border-b-[3px] border-transparent text-sm whitespace-nowrap  hover:text-teal-600 ${activeTab === 'Minutes' ? 'hs-tab-active' : ''}`}
                                        onClick={() => setActiveTab('Chat')}
                                    >
                                        Chat with Transcript
                                    </button></>}
                            </nav>
                        </div>
                        <button
                            onClick={() => downloadTxtFile(activeTab)}
                            className="flex w-fit justify-end text-sm content-center items-center px-6 h-8 uppercase tracking-wider border-2 border-black bg-teal-400 text-black hover:shadow-lg hover:shadow-teal-500/50"
                        >
                            Download
                        </button>
                    </div>
                    <div className="mt-3">
                        <div className="m-4 p-4 chat-box bg-white relative text-sm mt-0 pt-0" id="chatBox">
                            {activeTab !== 'Speakers' && activeTab !== 'Chat' && <div className='flex justify-between items-end mb-4'>
                                {(showProgress < 99 && showProgress !== null) && <div className='flex   items-start'><div className="custom-loader flex"></div><span className='text-sm'> &nbsp;We are processing your file, it may take few minutes, progress is  ({showProgress}%)</span></div>}

                            </div>}
                            <div className="md:overflow-y-auto  scroll-box" style={{ maxHeight: 'calc(100vh - 290px)' }}>

                                <TabContent updateTranscript={props.updateTranscript} transcriptMardown={transcriptMardown} slides={slides}/></div>
                        </div>
                    </div>
                </> : <div className="mt-3">
                    <div className="m-4 p-4 chat-box bg-white relative text-sm mt-0 pt-0" ><span>Your file is processing, we will notify by email when it will be ready</span></div></div>
            ) : <div className="mt-3">
                <div className="m-4 p-4 chat-box bg-white relative text-sm mt-0 pt-0"  ><span>Pick a recording to see details</span></div></div>
            }
        </>
    )
}

export default RenderedTranscript;
