export const starterSubscription = {
    title: "Starter subscription - free week trial!",
    description: "Great flexible option to try out TalkFlow and see how it works for you.",
    price: {
        amount: 5,
        frequency: "per week/billed weekly",
        display: "$5"
    },
    features: [
        "✅ &nbsp;<strong className='font-mono mr-1'>7 days</strong> free trial. <strong className='font-mono mr-1'>1,5 hours</strong> of meeting live assistance for a week",

        "✅ &nbsp;<strong className='font-mono mr-1'>1,5 hours</strong> of meeting live assistance for a week",
        "✅ &nbsp;<strong className='font-mono mr-1'>3 hours</strong> of meeting transcription uploads weekly",
        "✅ &nbsp;<strong className='font-mono mr-1'>AI meeting notes</strong> for uploaded meeting recordings",
        "✅ &nbsp;<strong className='font-mono mr-1'>History</strong> of transcripts",
        "✅ &nbsp;<strong className='font-mono mr-1'>Unlimited</strong> download of your uploaded and live transcripts"
    ],
    buttonId: "14"
};

export const baseSubscription = {
    title: "Base subscription",
    description: "If you want always available AI assistant.",
    price: {
        amount: 20,
        frequency: "per month/billed monthly",
        display: "$20"
    },
    features: [
        "✅ &nbsp;<strong className='font-mono mr-1'>7 days</strong> free trial. <strong className='font-mono mr-1'>10 hours</strong> of meeting live assistance",

        "😊 &nbsp;<strong className='font-mono mr-1'>10 hours</strong> of meeting live assistance",
        "😊 &nbsp;<strong className='font-mono mr-1'>20 hours</strong> of meeting transcription monthly",
        "✅ &nbsp;<strong className='font-mono mr-1'>AI meeting notes</strong> for uploaded meeting recordings",
        "✅ &nbsp;<strong className='font-mono mr-1'>History</strong> of transcripts",
        "✅ &nbsp;<strong className='font-mono mr-1'>Unlimited</strong> download of your uploaded and live transcripts"
    ],
    buttonId: "11"
};

export const advancedSubscription = {
    title: "Advanced subscription",
    description: "For those who spend a lot of time on online meetings and need extra credits.",
    price: {
        amount: 35,
        frequency: "per month/billed monthly",
        display: "$35"
    },
    features: [
        "😊 &nbsp;<strong className='font-mono mr-1'>25 hours</strong> of meeting live assistance",
        "😊 &nbsp;<strong className='font-mono mr-1'>40 hours</strong> of meeting transcription monthly",
        "✅ &nbsp;<strong className='font-mono mr-1'>AI meeting notes</strong> for uploaded meeting recordings",
        "✅ &nbsp;<strong className='font-mono mr-1'>History</strong> of transcripts",
        "✅ &nbsp;<strong className='font-mono mr-1'>Unlimited</strong> download of your uploaded and live transcripts"
    ],
    buttonId: "12"
};
